import * as CustomerApi from 'api/customer-api';
import * as customerMessages from 'constants/customer-messages';
import * as eventLabels from 'constants/GA';
import { otpCodeInvalid, otpMaxTries, otpExpired } from 'constants/errors';
import { fail } from './fail';
import { failOtp } from './fail-otp';
import has from 'lodash/has';
import { metricsError } from './metrics';
import { trackError as trackOtpError, trackSuccess as trackOtpSuccess } from './send-otp';

export const REQUEST = 'signup/REQUEST';
export const RECEIVED = 'signup/RECEIVED';
export const RECEIVED_GA4 = 'signup/RECEIVED_GA4';
export const FINISHED = 'signup/FINISHED';
export const TRACK_ERROR = 'signup/TRACK_ERROR';
export const TRACK_SUBMIT = 'signup/TRACK_SUBMIT';
export const DID_CREATE_MPAY = 'signup/DID_CREATE_MPAY';
export const DID_CREATE_MPAY_GA4 = 'signup/DID_CREATE_MPAY_GA4';
export const DID_TERMS_KNOW_MORE_VIEW = 'signup/DID_TERMS_KNOW_MORE_VIEW';
export const VIEW_SINGUP_GA4 = 'login/VIEW_SINGUP_GA4';
export const ACCEPT_PRIVACY_POLICY_GA4 = 'signup/ACCEPT_PRIVACY_POLICY_GA4';
export const READ_POLICY_GA4 = 'signup/READ_POLICY_GA4';
export const OPTIONS_NOTIFICATIONS_GA4 = 'signup/OPTIONS_NOTIFICATIONS_GA4';
export const LEARN_MORE_MPAY_GA4 = 'signup/LEARN_MORE_MPAY_GA4';
export const TERMS_USE_GA4 = 'signup/TERMS_USE_GA4';
export const CONTINUE_CHECKOU_GA4 = 'signup/CONTINUE_CHECKOU_GA4';

export const initialState = {
  isRequesting: false,
  accountType: '',
  formData: {},
  signupInfo: {},
};

export default function reducer(state = initialState, action = {}) {
  const { signupInfo } = action;
  switch (action.type) {
    case REQUEST:
      const { accountType, formData } = action;
      return Object.assign({}, state, { isRequesting: true, accountType, formData });
    case RECEIVED:
      return Object.assign({}, state, { isRequesting: false, signupInfo });
    case RECEIVED_GA4:
      return Object.assign({}, state, { isRequesting: false, signupInfo });
    case FINISHED:
      return Object.assign({}, state, { isRequesting: false });
    case VIEW_SINGUP_GA4:
      const { info, formData: data } = action;
      return Object.assign({}, state, { isRequesting: false, data, info });
    default:
      return state;
  }
}

export function signUpPageviewGA4(formData, info) {
  return {
    type: VIEW_SINGUP_GA4,
    formData,
    info,
  };
}

export function requestSignup(accountType, formData) {
  return { type: REQUEST, accountType, formData };
}

export function receiveSignup(signupInfo) {
  return { type: RECEIVED, signupInfo };
}
export function receiveSignupGA4(signupInfo) {
  return { type: RECEIVED_GA4, signupInfo };
}

export function trackError(errorMessage, page) {
  return { type: TRACK_ERROR, errorMessage, page };
}

export function trackSubmit() {
  return { type: TRACK_SUBMIT };
}

export function setNotificationsGA4(message) {
  return { type: OPTIONS_NOTIFICATIONS_GA4, message };
}

export function learnMoreMpayGA4(){
  return {type: LEARN_MORE_MPAY_GA4};
}

export function termsOfUseGA4(){
  return {type: TERMS_USE_GA4};
}

export function didCreateMpay(checked) {
  return { type: DID_CREATE_MPAY, checked };
}

export function didCreateMpayGA4() {
  return { type: DID_CREATE_MPAY_GA4 };
}

export function didTermsKnowMoreView(source) {
  return { type: DID_TERMS_KNOW_MORE_VIEW, source };
}

export function acceptPrivacyPolicyGA4(checked) {
  return { type: ACCEPT_PRIVACY_POLICY_GA4, checked };
}

export function readPolicyGA4() {
  return { type: READ_POLICY_GA4 };
}

export function continueToCheckoutGA4() {
  return { type: CONTINUE_CHECKOU_GA4 };
}


export function finish() {
  return { type: FINISHED };
}

export const otpExceptions = [
  {
    key: otpCodeInvalid,
    message: customerMessages.invalidOtpCode,
    eventLabel: eventLabels.invalidOtpCodeEventLabel,
  },
  {
    key: otpMaxTries,
    message: customerMessages.maxTries,
    eventLabel: eventLabels.otpOtherErrorsEventLabel,
  },
  {
    key: otpExpired,
    message: customerMessages.otpExpired,
    eventLabel: eventLabels.otpExpiredEventLabel,
  },
];

export function postSignup(accountType, formData, captchaToken, page) {

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { signup: signupState = {} } = getState();
      let request;

      if (signupState.isRequesting) {
        return reject();
      }

      dispatch(requestSignup(accountType, formData));

      if (accountType === 'pf') {
        request = CustomerApi.postSaveCustomerPF(formData, captchaToken);
      } else {
        request = CustomerApi.postSaveCustomerPJ(formData, captchaToken);
      }

      request.then(
        (info) => {
          dispatch(receiveSignup(info));
          dispatch(receiveSignupGA4(info));
          dispatch(trackOtpSuccess('codigo-valido'));
          if (!info.customerId) {
            dispatch(signUpPageviewGA4(formData, info));
          }

          return resolve();
        },
        (err) => {
          if (err?.response?.body) {
            const { message, status, error_message: errorMessage } = err.response.body;

            if (has(errorMessage, 'data.fieldErrors.oldPassword')) {
              dispatch(fail(customerMessages.invalidOldPassword, err.status));
              dispatch(trackError(customerMessages.invalidOldPassword));
            } else {
              const otpMappedError = otpExceptions.find(
                (otpExpection) => otpExpection.key === message
              );
              dispatch(failOtp(otpMappedError?.message, status));
              dispatch(
                trackOtpError(
                  otpMappedError?.eventLabel || eventLabels.otpOtherErrorsEventLabel,
                  page
                )
              );
            }

            dispatch(metricsError('concluir cadastro CPF/CNPJ', errorMessage));
          } else {
            dispatch(fail(customerMessages.failSignup, err.status));
            dispatch(trackError(customerMessages.failSignup));
          }

          dispatch(finish());
          return reject(err);
        }
      );
    });
  };
}

export function clickNotification( message) {
  return (dispatch) => dispatch(setNotificationsGA4(message));
}

export function optDidUpdate(checked) {
  return (dispatch) => dispatch(didCreateMpay(checked));
}

export function optDidUpdateGA4() {
  return (dispatch) => dispatch(didCreateMpayGA4());
}

export function optKnowMoreClick() {
  return (dispatch) => dispatch(didTermsKnowMoreView('saiba-mais-mpay'));
}

export function optTermsClick() {
  return (dispatch) => dispatch(didTermsKnowMoreView('termos-conta-mpay'));
}

export function optTermsPrivacyPolicyClick(checked) {
  return (dispatch) => dispatch(acceptPrivacyPolicyGA4(checked));
}

export function clickLearnMoreMpayGA4() {
  return (dispatch) => dispatch(learnMoreMpayGA4());
}

export function clickTermsOfUseGA4() {
  return (dispatch) => dispatch(termsOfUseGA4());
}

export function clickReadPolicyGA4() {
  return (dispatch) => dispatch(readPolicyGA4());
}

export function clickContinueToCheckoutGA4() {
  return (dispatch) => dispatch(continueToCheckoutGA4());
}
