import * as CustomerApi from 'api/customer-api';
import * as customerMessages from 'constants/customer-messages';
import { fail } from './fail';
import { metricsError } from './metrics';

export const REQUEST = 'social-login/REQUEST';
export const SAVE = 'social-login/SAVE';
export const RECEIVED = 'social-login/RECEIVED';
export const RECEIVED_LOGIN_GA4 = 'social-login/RECEIVED_LOGIN_GA4';
export const RECEIVED_SIGN_UP_GA4 = 'social-login/RECEIVED_SIGN_UP_GA4';
export const RESET = 'social-login/RESET';
export const TRACK_ERROR = 'social-login/TRACK_ERROR';
export const FINISHED = 'social-login/FINISHED';
export const REQUEST_PROFILE = 'social-login/REQUEST-PROFILE';
export const RECEIVED_PROFILE = 'social-login/RECEIVED-PROFILE';

export const initialState = {
  isRequesting: false,
  source: '',
  socialClientId: '',
  email: '',
  socialInfo: {}, // info from our service
  profileInfo: {} // info from social network
};

export default function reducer(state = initialState, action = {}) {
  const { source, socialClientId, email, profileInfo } = action;

  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, {
        isRequesting: true
      });
    case SAVE:
      return Object.assign({}, state, {
        isRequesting: true,
        source,
        socialClientId,
        email
      });
    case RECEIVED:
      const { socialInfo } = action;
      return Object.assign({}, state, {
        isRequesting: false,
        socialInfo
      });
    case RECEIVED_LOGIN_GA4:
    case RECEIVED_SIGN_UP_GA4:
    case RESET:
      return { ...initialState };
    case FINISHED:
      return Object.assign({}, state, { isRequesting: false });
    case RECEIVED_PROFILE:
      return Object.assign({}, state, { profileInfo });
    default:
      return state;
  }
}

export function requestSocialLogin() {
  return { type: REQUEST };
}

export function saveSocialLogin(source, socialClientId, email) {
  return { type: SAVE, source, socialClientId, email };
}

export function receiveSocialLogin(socialInfo, source) {
  return { type: RECEIVED, socialInfo, source };
}
export function receiveSocialLoginGA4(source) {
  return { type: RECEIVED_LOGIN_GA4, source };
}
export function receiveSocialSignUpGA4(source) {
  return { type: RECEIVED_SIGN_UP_GA4, source };
}

export function reset() {
  return { type: RESET };
}

export function finish() {
  return { type: FINISHED };
}

export function requestProfile(source) {
  return { type: REQUEST_PROFILE, source };
}

export function receiveProfile(profileInfo) {
  return { type: RECEIVED_PROFILE, profileInfo };
}

export function trackError(errorMessage, source) {
  return { type: TRACK_ERROR, source, errorMessage };
}

export function fetchSocialLogin(source, accessToken, socialClientId, email) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { socialLogin: socialLoginState = {} } = getState();

      if (socialLoginState.isRequesting) {
        return reject();
      }

      dispatch(requestSocialLogin());

      return CustomerApi.getSocialLogin(source, accessToken, socialClientId, email)
        .then(socialInfo => {
          dispatch(receiveSocialLogin(socialInfo, source));
          dispatch(receiveSocialLoginGA4(source));

          return resolve({ status: 200, ...socialInfo });
        }, err => {
          const { status } = err;

          if (status !== 404) {
            dispatch(trackError(customerMessages.socialLoginFail, source));
          }

          dispatch(metricsError('login social', err?.response?.body?.error_message));

          dispatch(finish());
          return reject(err);
        });
    });
  };
}

export function postSocialLogin() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const {
        socialLogin: {
          isRequesting,
          profileInfo: {
            source,
            socialClientId,
            email
          } = {}
        } = {}
      } = getState();

      if (isRequesting) {
        return reject();
      }

      dispatch(saveSocialLogin(source, socialClientId, email));

      return CustomerApi.postSocialSave(source)
        .then(socialInfo => {
          dispatch(receiveSocialLogin(socialInfo));
          dispatch(receiveSocialLoginGA4(source));
          dispatch(receiveSocialSignUpGA4(source));
          return resolve(socialInfo);
        })
        .catch(err => {
          dispatch(fail(customerMessages.saveSocialLoginFail, err.status));

          dispatch(metricsError('salvar login social', err.response.body.error_message));

          dispatch(finish());
          return reject(err);
        });
    });
  };
}
