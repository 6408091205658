import slugify from 'utils/slugify';
import { getProductFromState, serviceLayer } from 'utils/data-layer/helpers';
import { editCartEcommerceDataGA4 } from 'utils/data-layer/addToCartEcommerceData';
import { addToCartLayer } from 'utils/data-layer/addToCartLayer';
import { pageviewLayer } from 'utils/data-layer/pageviewLayer';
import { viewCart, DEFAULT_PAGE_TYPE_CATEGORY } from './viewCart';
import { checkoutProgress } from './checkoutProgress';
import { tax } from './tax';
import { failShipmentNotFound } from 'constants/customer-messages';

export const isSoldOut = (action) => {
  if (action.product.isSoldOut === true) {
    return 'falso';
  }
  if (action.product.isSoldOut === false) {
    return 'verdadeiro';
  }
  return 'undefined';
};

export const basketPageview = (action, stateBefore, stateAfter) =>
  pageviewLayer(stateAfter, '1');

export const basketCheckoutProgress = (action, stateBefore, stateAfter) => {
  return checkoutProgress({
    pageType: DEFAULT_PAGE_TYPE_CATEGORY.basket,
    stateAfter,
  });
};

export const basketViewCart = (action, stateBefore, stateAfter) => {
  return viewCart({
    pageType: DEFAULT_PAGE_TYPE_CATEGORY.basket,
    stateAfter,
  });
};

export const basketTax = (action, stateBefore, stateAfter) => {
  return tax({
    screenName: action.screenName,
    stateAfter
  });
};

export function changeItemQty(action) {
  const layer = { ecommerce: {} };
  const quantityModified = Math.abs(action.product.quantity - action.quantity);

  if (action.product.quantity < action.quantity) {
    layer.event = 'addToCart';
    layer.eventCategory = 'sacola:produto';
    layer.eventAction = 'add-to-cart';
    layer.eventLabel = 'aumentar-quantidade';
    layer.screenName = 'carrinho';
    layer.currency = 'BRL';
    layer.value = action.product.prices.cash || action.product.price;

    layer.ecommerce.add = {};
    layer.ecommerce.add.products = [];
    layer.ecommerce.add.products[0] = editCartEcommerceDataGA4(
      action.product,
      quantityModified
    );

    action.product.services.map((service) => {
      layer.ecommerce.add.products.push(serviceLayer(service, quantityModified));
    });
  } else {
    layer.eventNonInteraction = '0';
    layer.produtoDisponivel = isSoldOut(action);
    layer.sku = action.itemId;
    layer.hitTimestamp = `${Date.now()}`;

    layer.event = 'removeFromCart';
    layer.eventCategory = 'sacola:produto';
    layer.eventAction = 'remove-from-cart';
    layer.eventLabel = 'diminuir-quantidade';
    layer.screenName = 'carrinho';

    layer.ecommerce.remove = {};
    layer.ecommerce.remove.products = [];
    layer.ecommerce.remove.products[0] = editCartEcommerceDataGA4(
      action.product,
      quantityModified
    );

    action.product.services.map((service) => {
      layer.ecommerce.remove.products.push(serviceLayer(service, quantityModified));
    });
  }

  return layer;
}

export function removeToCart(action, stateBefore, stateAfter) {
  const result = [];

  action.items.forEach((item) => {
    const product = getProductFromState(stateBefore, item.id);
    const layer = addToCartLayer(product);

    const productHasServices = product.services.length;

    if (productHasServices) {
      layer.eventCategory = 'sacola:servico';
    } else {
      layer.eventCategory = 'sacola:qtd-produto';
    }

    layer.eventNonInteraction = '0';

    layer.event = 'removeFromCart';
    layer.eventAction = 'remove-from-cart';
    layer.eventCategory = 'sacola:produto';
    layer.eventLabel = 'excluir-item';
    layer.hitTimestamp = `${Date.now()}`;
    layer.screenName = 'carrinho';

    layer.ecommerce = {
      remove: {
        products: [editCartEcommerceDataGA4(product, product.quantity)],
      },
    };

    product.services.map((service) => {
      layer.ecommerce.remove.products.push(serviceLayer(service, product.quantity));
    });

    result.push(layer);
  });

  return result;
}

export function basketShippingCalcProductLayer(action, stateBefore, screenName) {
  const { shipment: { notAvailableProducts, packages, zipcode: zipcodeShipment }, zipcode ,error } = action || {};
  const productNotAvailable = notAvailableProducts[0]?.sku || notAvailableProducts[0]?.items[0]?.sku || false;
  const basketId = stateBefore?.basket?.basket?.id;
  const isProductsNotAvailable = packages.length === 0 && notAvailableProducts.length > 0;
  const layer = {
    event: 'shipping',
    basketId,
    cep: zipcode || zipcodeShipment || null,
    screenName: screenName || 'carrinho',
    content_type: `${screenName || 'carrinho'}:frete`,
    content_id: error === failShipmentNotFound ? 'cep-invalido' : null,
    deliveriesId: packages?.[0]?.deliveryId || null,
    code: 'OK:null',
    item_id: `${packages?.[0]?.products?.[0]?.sku || null}${
      productNotAvailable ? `|${productNotAvailable}` : ''
    }:null:${packages?.[0]?.deliveryTypes?.[0]?.type || null}:${
      packages?.[1]?.deliveryTypes?.[0]?.type || null
    }`
  };

  if(isProductsNotAvailable) {
    const { status_message: statusMessage, id, sku } = notAvailableProducts[0];

    const firstProduct = notAvailableProducts[0].sku ? sku : notAvailableProducts[0]?.items[0]?.sku || null;
    const secondProductNotAvailable = notAvailableProducts[1]?.sku ? notAvailableProducts[1] : notAvailableProducts[0]?.items?.[1] || false;
    layer.code = `resource-not-found:${slugify(statusMessage) || null}`;
    layer.item_id = `${firstProduct}${secondProductNotAvailable ? `|${secondProductNotAvailable?.sku}` : ''}:null:null`;
    layer.deliveriesId = id || null;
    layer.content_id = slugify(statusMessage);
  }

  return layer;
}

function handleBasketFetchError({ error, zipcode, itemId }, stateBefore) {
  const eventLabelError = error?.response?.body?.error_message || `erro${error.status}`;

  return {
    event: 'shipping_error',
    action: 'impression',
    screenName: 'carrinho',
    cep: zipcode || null,
    code: null,
    content_type: 'sacola:frete',
    deliveriesId: null,
    item_id: `${itemId || null}:${slugify(eventLabelError)}`,
  };
}

export function basketShippingCalcError(action, stateBefore) {
  const { unavailableDeliveries = [] } = action.basket || {};

  if (action.error && action.error !== failShipmentNotFound) {
    return handleBasketFetchError(action, stateBefore);
  }

  return unavailableDeliveries?.map((delivery) => ({
    event: 'shipping_error',
    action: 'impression',
    screenName: 'carrinho',
    cep: action?.shipment?.zipcode || action.zipcode || null,
    code: null,
    content_type: 'sacola:frete',
    deliveriesId: delivery.sku,
    item_id: `${delivery.sku || null}:${slugify(delivery.status_message)}`,
  }));
}

export function basketShippingCalc(action, stateBefore) {
  return {
    ...basketShippingCalcProductLayer(action, stateBefore, ''),
  };
}

export function basketShippingCalcImpression(action, stateBefore) {
  return {
    ...basketShippingCalcProductLayer(stateBefore.shipment, stateBefore, 'sacola'),
  };
}

export function removeService(action) {
  const service = action.services[0].service;
  const layer = addToCartLayer(action.product);

  layer.event = 'removeFromCart';
  layer.eventCategory = 'sacola:produto';
  layer.eventAction = 'remove-from-cart';
  layer.eventLabel = 'excluir-item';
  layer.screenName = 'carrinho';

  layer.ecommerce = {
    remove: {
      products: [serviceLayer(service, action.product.quantity)],
    },
  };

  return layer;
}
