import moment from 'moment';
import { getChannelConfig } from 'utils/session';


import { GOOGLE, FACEBOOK } from 'constants/social-login';

moment.locale('pt-br');

export const customer = (payload = {}) => {
  const {
    name: fullName,
    email,
    cpfCnpj,
    isPerson,
    field_errors: registrationErrors = [],
    customer_id: customerId,
    customer_email: customerEmail,
    enabled_customer_cart_merge_redirect: enabledCustomerCartMergeRedirect,
  } = payload;

  return {
    fullName,
    email,
    cpfCnpj,
    isPerson,
    registrationErrors,
    customerId,
    customerEmail,
    enabledCustomerCartMergeRedirect,
  };
};

export const fullCustomerPF = (payload = {}) => {
  const {
    id: customerUuid,
    name: fullName,
    cpfCnpj: cpf,
    zipCode: zipcode,
    email,
    person: {
      cellPhoneAreaCode,
      cellPhone,
      birthDate,
      address,
      number,
      complement,
      village: neighbourhood,
      city,
      state,
      localReference
    },
    notifications = {},
    opt = {},
    otpId,
    otpCode,
    pendingDataChange
  } = payload;
  const telephone = `${cellPhoneAreaCode}${cellPhone}`;
  const cleanComplement = complement !== 'None' && complement || '';
  const cleanReference = localReference !== 'None' && localReference || '';

  return {
    customerUuid,
    fullName,
    cpf,
    zipcode,
    email,
    telephone,
    birthDate: birthDate && moment(birthDate),
    address,
    number,
    complement: cleanComplement,
    neighbourhood,
    city,
    state,
    reference: cleanReference,
    accountType: 'pf',
    notifications,
    opt,
    otpId,
    otpCode,
    pendingDataChange
  };
};

export const fullCustomerPJ = (payload = {}) => {
  const {
    id: customerUuid,
    name: companyName,
    nickName: fantasyName,
    cpfCnpj: cnpj,
    zipCode: zipcode,
    email,
    company: {
      address,
      cellPhone,
      cellPhoneAreaCode,
      city,
      comercialPhone: comercialPhoneNumber,
      comercialPhoneAreaCode,
      homePhone: homePhoneNumber,
      homePhoneAreaCode,
      number,
      responsibleName: fullName,
      state,
      localReference,
      stateInscription: stateRegistration,
      village: neighbourhood,
      complement
    },
    notifications = {},
    otpId,
    otpCode,
    pendingDataChange
  } = payload;
  const homePhone = `${homePhoneAreaCode}${homePhoneNumber}`;
  const mobilePhone = `${cellPhoneAreaCode}${cellPhone}`;
  const comercialPhone = `${comercialPhoneAreaCode}${comercialPhoneNumber}`;
  const cleanComplement = complement !== 'None' && complement || '';
  const cleanReference = localReference !== 'None' && localReference || '';
  const exemptString = 'ISENTO';

  return {
    customerUuid,
    email,
    cnpj,
    companyName,
    fantasyName,
    stateRegistration: stateRegistration !== exemptString && stateRegistration || '',
    isExemptFromStateRegistration: stateRegistration === exemptString,
    fullName,
    homePhone,
    comercialPhone,
    mobilePhone,
    zipcode,
    address,
    number,
    complement: cleanComplement,
    neighbourhood,
    city,
    state,
    reference: cleanReference,
    accountType: 'pj',
    notifications,
    otpId,
    otpCode,
    pendingDataChange
  };
};

export const truncatedEmail = (payload = {}) => {
  const { email } = payload;

  return { email };
};

export const socialLoginUser = (payload = {}) => {
  const { socialClientId, email, clientId, socialId, enabled_customer_cart_merge_redirect: enabledCustomerCartMergeRedirect } = payload;

  return {
    socialClientId,
    email,
    clientId,
    socialId,
    enabledCustomerCartMergeRedirect,
  };
};

export const socialSourceId = (sourceName) => {
  switch (sourceName) {
    case FACEBOOK:
      return 1;
    case GOOGLE:
      return 2;
    default:
      return 0;
  }
};

export const telephoneData = (telephone) => {
  return {
    areaCode: telephone.substr(0, 2),
    number: telephone.substr(2)
  };
};

export const customerPFData = ({
  fullName,
  cpf,
  channelName,
  telephone,
  birthDate,
  oldPassword,
  password,
  zipcode,
  address,
  number,
  complement,
  neighbourhood,
  city,
  state,
  reference,
  email,
  customerUuid = '',
  notifications: {
    pushPromotional,
    smsPromotional,
    emailPromotional,
    mailingPromotional,
    whatsappPromotional,
    whatsappTransactional,
    whatsappTelemarketingPromotional,
    telemarketingPromotional,
  } = {},
  opt: {
    magalupay,
  } = {},
  otpId,
  otpCode,
  otpDeliveryMode
}) => {
  const phone = telephoneData(telephone);
  let data = {
    name: fullName,
    channel_name: channelName,
    nick_name: fullName,
    cpf_cnpj: cpf,
    birth_date: birthDate,
    email,
    password,
    zip_code: zipcode,
    address,
    number,
    complement,
    village: neighbourhood,
    city,
    state,
    reference,
    cell_phone_area_code: phone.areaCode,
    cell_phone: phone.number,
    notifications: {
      push_promotional: pushPromotional,
      sms_promotional: smsPromotional,
      email_promotional: emailPromotional,
      mailing_promotional: mailingPromotional,
      whatsapp_promotional: whatsappPromotional,
      whatsapp_transactional: whatsappTransactional,
      whatsapp_telemarketing_promotional: whatsappTelemarketingPromotional,
      telemarketing_promotional: telemarketingPromotional,
    },
    opt: {
      magalupay
    },
    otp_id: otpId,
    otp_code: otpCode,
    otp_delivery_mode: otpDeliveryMode
  };

  if (!getChannelConfig('enable_magalupay_optin')) delete data.opt;

  if (customerUuid) data = Object.assign({}, data, { uuid: customerUuid });

  if (oldPassword) data = Object.assign({}, data, { old_password: oldPassword });

  return data;
};

export const customerPJData = ({
  cnpj,
  channelName,
  companyName,
  fantasyName,
  stateRegistration,
  isExemptFromStateRegistration,
  fullName,
  homePhone,
  comercialPhone,
  mobilePhone,
  oldPassword,
  password,
  zipcode,
  address,
  number,
  complement,
  neighbourhood,
  city,
  state,
  reference,
  email,
  customerUuid = '',
  notifications: {
    pushPromotional,
    smsPromotional,
    emailPromotional,
    mailingPromotional,
    whatsappPromotional,
    whatsappTransactional,
    whatsappTelemarketingPromotional,
    telemarketingPromotional,
  } = {},
  otpCode,
  otpId,
  otpDeliveryMode
}) => {
  const mobile = telephoneData(mobilePhone);
  const home = telephoneData(homePhone);
  const comercial = telephoneData(comercialPhone);
  const stateInscription = isExemptFromStateRegistration && 'ISENTO' || stateRegistration;
  let data = {
    name: companyName,
    channel_name: channelName,
    nick_name: fantasyName,
    cpf_cnpj: cnpj,
    email,
    password,
    zip_code: zipcode,
    address,
    number,
    complement,
    village: neighbourhood,
    city,
    state,
    reference,
    home_phone: home.number,
    home_phone_area_code: home.areaCode,
    comercial_phone: comercial.number,
    comercial_phone_area_code: comercial.areaCode,
    cell_phone: mobile.number,
    cell_phone_area_code: mobile.areaCode,
    state_inscription: stateInscription,
    responsible_name: fullName,
    notifications: {
      push_promotional: pushPromotional,
      sms_promotional: smsPromotional,
      email_promotional: emailPromotional,
      mailing_promotional: mailingPromotional,
      whatsapp_promotional: whatsappPromotional,
      whatsapp_transactional: whatsappTransactional,
      whatsapp_telemarketing_promotional: whatsappTelemarketingPromotional,
      telemarketing_promotional: telemarketingPromotional,
    },
    otp_id: otpId,
    otp_code: otpCode,
    otp_delivery_mode: otpDeliveryMode
  };

  if (customerUuid) {
    data = Object.assign({}, data, { uuid: customerUuid });
  }

  if (oldPassword) {
    data = Object.assign({}, data, { old_password: oldPassword });
  }

  return data;
};

export const socialProfile = (source, profile) => {
  if (source === FACEBOOK) {
    const { id: socialClientId, email, name: fullName, accessToken } = profile;
    return {
      socialClientId,
      email,
      fullName,
      accessToken
    };
  } else if (source === GOOGLE) {
    return profile;
  }
  return {};
};
