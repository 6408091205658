

export const learnMoreMpayGA4 = () => {
  const layer = {};
  
  layer.event = 'select_content';
  layer.content_type = 'identificacao:criar-conta';
  layer.content_id = 'saiba-mais-sobre-o-magalupay';

  return layer;

};