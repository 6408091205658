

export const readPolicyGA4 = () => {

  const layer = {};
  
  layer.event = 'select_content';
  layer.content_type = 'identificacao:criar-conta';
  layer.content_id = 'ler-politica-privacidade';

  return layer;

};