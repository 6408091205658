import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGroup from 'components/Form/FormGroup/FormGroup';
import InputRadioButton from 'components/Form/InputRadioButton/InputRadioButton';
import { privacyUrl } from 'constants/external-urls';

import { getChannelConfig } from 'utils/session';

import './SignupForm.scss';

const { bool, string, object, array, oneOfType } = PropTypes;
const enablePJRedirect = getChannelConfig(
  'enable_pj_customer_redirect_to_magalu_empresas'
) ?? false;

class SignupFormTypeRadio extends Component {
  static propTypes = {
    accountTypeField: object.isRequired,
    showPJ: bool
  };

  render() {
    const { accountTypeField, showPJ } = this.props;
    return (
      <div className="FormGroup">
        {!enablePJRedirect && (
          <label className="FormGroup-label FormGroup-radioLabel">Tipo de conta</label>
        )}
        <div className="FormGroup-radioGroup">
          {!enablePJRedirect && (
            <label htmlFor="type-pf" className="FormGroup-radioOption">
              <InputRadioButton
                checkedValue={accountTypeField.value === 'pf'}
              />
              <input
                className="FormGroup-radio InputRadioButton"
                id="type-pf"
                type="radio"
                value="pf"
                onChange={accountTypeField.onChange}
                checked={accountTypeField.value === 'pf'}
              />
              <span>
                  Pessoa física
              </span>
            </label>
          )}

          {(!enablePJRedirect && showPJ) && (
            <label htmlFor="type-pj" className="FormGroup-radioOption">
              <InputRadioButton
                checkedValue={accountTypeField.value === 'pj'}
              />
              <input
                className="FormGroup-radio InputRadioButton"
                id="type-pj"
                type="radio"
                value="pj"
                onChange={accountTypeField.onChange}
                checked={accountTypeField.value === 'pj'}
              />
              <span>
                Pessoa jurídica
              </span>
            </label>
          )}
        </div>
      </div>
    );
  }
}

export class SignupPolicy extends Component {
  render() {
    const { readPolicy } = this.props;

    return (
      <div className="SignupPolicy">
        Li e aceito a{' '}
        <a
          className="SignupPolicy-link"
          href={privacyUrl}
          target="_blank"
          rel="noopener"
          data-ga='{"category": "Criar cadastro", "action": "Política de privacidade", "label": ""}'
          onClick={readPolicy}
        >
          Política de privacidade
        </a>
        {' '}do Grupo Magazine Luiza.
      </div>
    );
  }
}

export default class SignupForm extends Component {
  static propTypes = {
    children: oneOfType([object, array]),
    email: object.isRequired,
    accountTypeField: object.isRequired,
    showPJ: bool,
    forceShowSuccess: bool
  };

  render() {
    const {
      children,
      email,
      accountTypeField,
      showPJ,
      forceShowSuccess = false
    } = this.props;

    return (
      <div className="SignupForm">
        <FormGroup
          labelText="E-mail"
          field={email}
          forceShowSuccess={forceShowSuccess}
          inputSize="long"
        />
        <SignupFormTypeRadio
          showPJ={!enablePJRedirect && showPJ}
          accountTypeField={accountTypeField}
        />
        {children}
      </div>
    );
  }
}
