

export const openMpayAccount = () => {
  const layer = {};
  
  layer.event = 'select_content';
  layer.content_type = 'identificacao:criar-conta';
  layer.content_id = 'quero-minha-conta-mpay';

  return layer;

};