

export const continueCheckoutGA4 = () => {
  const layer = {};
  
  layer.event = 'select_content';
  layer.content_type = 'identificacao:criar-conta';
  layer.content_id = 'cadastro-continuar';

  return layer;

};