export function signupResponseGA4(_action, _stateBefore, stateAfter) {
  const { profileInfo } = stateAfter?.socialLogin;

  const {
    whatsappPromotional,
    whatsappTransactional,
    emailPromotional,
    pushPromotional,
    smsPromotional,
    whatsappTelemarketingPromotional,
    telemarketingPromotional,
    mailingPromotional,
  } = stateAfter?.signup.formData.notifications;
  

  const cmpayAccepted = stateAfter?.signup.formData.opt.magalupay;

  const message =
    `lu-mensagens:${+whatsappPromotional}|` +
    `lu-pedido:${+whatsappTransactional}|` +
    `email:${+emailPromotional}|` +
    `push:${+pushPromotional}|` +
    `sms:${+smsPromotional}|` +
    `loja-whats:${+whatsappTelemarketingPromotional}|` +
    `loja-tel:${+telemarketingPromotional}|` +
    `carta:${+mailingPromotional}`;

  const layer = {};

  layer.event = 'sign_up';
  layer.method = profileInfo?.source || 'cpf-email';
  layer.account_type = stateAfter?.signup.accountType ?? null;
  layer.mpay_account = +cmpayAccepted;
  layer.notification = message;

  return layer;
}
