import cookies from 'browser-cookies';
import { detectMobile } from 'utils/responsive';
import { getCustomer, isLogged} from 'utils/session';
import {
  getChannelStoreName,
  getChannelStoreId,
  getCityUf,
  GTM_FALSE ,
  GTM_TRUE,
} from './helpers';


export const pageviewLayerGA4 = ({formData, info}) => {

  const nameStore = getChannelStoreName(formData?.channelName);
  const idStore = getChannelStoreId(formData?.channelName);
  const cityUF = getCityUf(formData?.city || '', formData?.state || '').toLowerCase();

  const layer = {};
  const customer = getCustomer();

  layer.event = 'virtualPageview';
  layer.clientId = info?.customerId || null;

  layer.idParceiro = (window.MAGALU_CHANNEL?.channel_name === 'clube' ?
    cookies.get('MLPARCEIRO_clube') :
    cookies.get('MLPARCEIRO'));
  layer.cidadeuf = cityUF === null || cityUF === undefined ? cityUF : null;
  layer.loginAtivo = isLogged() ? 'logado' : 'nao-logado';
  layer.idUsuario = customer?.id ?? null ;
  layer.screenName = 'cadastro';
  layer.usuarioClube = isLogged() && cookies.get('MLPARCEIRO_clube') ? GTM_TRUE : GTM_FALSE ?? null;
  layer.experimentId = null;
  layer.pageType = 'cadastro';
  layer.cepPreenchido = formData?.city && formData?.state ? GTM_TRUE : GTM_FALSE ?? null;
  layer.device = detectMobile() ? 'site-mobile' : 'site-desktop';
  layer.nomeDaLoja = nameStore === null || nameStore === undefined ? nameStore : null;
  layer.idDaLoja = idStore === null || idStore === undefined ? idStore : null;
  
  return layer;
};