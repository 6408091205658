

export const termsOfUseGA4 = () => {
  const layer = {};
  
  layer.event = 'select_content';
  layer.content_type = 'identificacao:criar-conta';
  layer.content_id = 'termos-de-uso';

  return layer;

};