import React from 'react';
import PropTypes from 'prop-types';

import { similarProductUrl } from 'utils/urlChannelParser';
import { maxProductQuantityBySku } from 'utils/product';
import { getChannelConfig } from 'utils/session';

import BasketAvailabilityWarning from 'components/Basket/Warnings/BasketAvailabilityWarning';
import BasketUnavailableWarning from 'components/Basket/Warnings/BasketUnavailableWarning';
import BasketSoldOutWarning from 'components/Basket/Warnings/BasketSoldOutWarning';
import BasketItemNotAllowedForCompaniesWarning from 'components/Basket/Warnings/BasketItemNotAllowedForCompaniesWarning';
import BasketItemProduct from 'components/Basket/Item/BasketItemProduct/BasketItemProduct';
import BasketItemWarranty from 'components/Basket/Item/BasketItemWarranty/BasketItemWarranty';
import BasketItemQuantity from 'components/Basket/Item/BasketItemQuantity';
import BasketItemServiceQuantity from 'components/Basket/Item/BasketItemServiceQuantity';
import BasketItemPrice from 'components/Basket/Item/BasketItemPrice';

import './BasketItem.scss';
import BasketPolicyViolation from '../BasketPolicyViolation/BasketPolicyViolation';
import isInternationalPrice from 'utils/isInternationalPrice';

const BasketItem = ({
  product: {
    title,
    store,
    prices,
    warranty,
    id,
    rawPictureUrl,
    fullPictureUrl,
    reference,
    quantity,
    availability,
    gifts,
    notAvailableForDelivery,
    categoryId,
    subcategoryUrl,
    variations,
    services,
    maxQuantity,
    minQuantity,
  },
  zipcode,
  shippingTimes,
  handleItemQuantityUpdate,
  handleItemRemove,
  handleWarrantyRemove,
  productUrl,
  handleServiceRemove,
  customerPerson,
  channelConfigs,
  maxProductQuantity,
  restrictedSkus,
  isSoldOut,
  isItemNotAllowedForCompanies,
  violatedPolicies,
  isLastItemForKey,
  currency,
  tax,
  unavailableDeliveryInfo,
}) => {
  const warrantySeller = getChannelConfig('warranty_seller');
  const isInternational = isInternationalPrice({ currency });
  const unavailableItem =
    (zipcode && notAvailableForDelivery) || isSoldOut || isItemNotAllowedForCompanies;

  const servicesList = (list, productId) =>
    list.map((service) => (
      <div
        key={`product-${productId}-service-${service?.id}`}
        className="BasketItem-warrantyContainer">
        <BasketItemWarranty name={service.name} />
        {store !== warrantySeller && (
          <BasketItemServiceQuantity
            quantity={quantity}
            disabled
            handleItemRemove={(e) => handleServiceRemove(e, service)}
          />
        )}
        <BasketItemPrice
          prices={{ to: service.price }}
          unavailable={unavailableItem}
          services={services}
        />
      </div>
    ));

  const {
    origin_url: originUrl,
    full_showcase_name: fullShowcaseName,
    similar_url_pattern: similarUrlPattern,
  } = channelConfigs;

  const urlSimilarProduct = similarProductUrl(
    similarUrlPattern,
    originUrl,
    fullShowcaseName,
    categoryId,
    subcategoryUrl
  );

  const product = {
    id,
    title,
  };

  const borderStyle = isLastItemForKey ? '-border-solid' : '-border-dashed';

  return (
    <div className={`BasketItem BasketItem${borderStyle}`}>
      {isSoldOut && <BasketSoldOutWarning subcategoryUrl={urlSimilarProduct} id={id} statusMessage={unavailableDeliveryInfo?.status_message}/>}

      {isItemNotAllowedForCompanies && (
        <BasketItemNotAllowedForCompaniesWarning
          subcategoryUrl={urlSimilarProduct}
          id={id}
        />
      )}

      {!isSoldOut && notAvailableForDelivery && zipcode && (
        <BasketUnavailableWarning
          zipcode={zipcode}
          subcategoryUrl={urlSimilarProduct}
          id={id}
          statusMessage={unavailableDeliveryInfo?.status_message}
        />
      )}

      {availability === 'some_regions' && !zipcode && <BasketAvailabilityWarning />}

      {violatedPolicies && (
        <BasketPolicyViolation product={product} policies={violatedPolicies} />
      )}

      <div className="BasketItem-productContainer">
        <BasketItemProduct
          id={id}
          title={title}
          store={store}
          gifts={gifts}
          shippingTimes={shippingTimes}
          variations={variations}
          rawPictureUrl={rawPictureUrl}
          fullPictureUrl={fullPictureUrl}
          reference={reference}
          productUrl={productUrl}
          customerPerson={customerPerson}
          isSoldOut={isSoldOut}
          services={services}
          unavailable={unavailableItem}
          isInternational={isInternational}
        />
        <BasketItemQuantity
          quantity={quantity}
          maxProductQuantity={
            maxQuantity
              ? maxQuantity
              : maxProductQuantityBySku(id, maxProductQuantity, restrictedSkus)
          }
          handleItemQuantityUpdate={handleItemQuantityUpdate}
          handleItemRemove={handleItemRemove}
          maxQuantity={maxQuantity}
          minQuantity={minQuantity}
          unavailable={unavailableItem}
        />
        {store !== warrantySeller && (
          <BasketItemPrice
            isInternational={isInternational}
            unavailable={unavailableItem}
            prices={prices}
            tax={tax}
          />
        )}
      </div>

      {warranty && (
        <div className="BasketItem-warrantyContainer">
          <BasketItemWarranty name={warranty.name} />
          <BasketItemQuantity
            maxProductQuantity={
              maxQuantity
                ? maxQuantity
                : maxProductQuantityBySku(id, maxProductQuantity, restrictedSkus)
            }
            quantity={quantity}
            disabled
            handleItemRemove={handleWarrantyRemove}
            maxQuantity={maxQuantity}
          />
          <BasketItemPrice
            prices={{ to: warranty.price }}
            unavailable={unavailableItem}
          />
        </div>
      )}

      {services && servicesList(services)}
    </div>
  );
};

BasketItem.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string.isRequired,
    store: PropTypes.string.isRequired,
    prices: PropTypes.shape({
      to: PropTypes.string.isRequired,
      cash: PropTypes.string,
      checkoutPriceDescription: PropTypes.string,
      unitPriceDescription: PropTypes.string,
    }).isRequired,
    warranty: PropTypes.shape({
      name: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
    }),
    id: PropTypes.string.isRequired,
    fullPictureUrl: PropTypes.string,
    rawPictureUrl: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    availability: PropTypes.string.isRequired,
    bundles: PropTypes.array,
    gifts: PropTypes.array,
    categoryId: PropTypes.string.isRequired,
    subcategoryUrl: PropTypes.string.isRequired,
    maxQuantity: PropTypes.number,
  }).isRequired,
  unavailable: PropTypes.bool,
  zipcode: PropTypes.string,
  handleItemQuantityUpdate: PropTypes.func.isRequired,
  handleItemRemove: PropTypes.func.isRequired,
  handleWarrantyRemove: PropTypes.func.isRequired,
  productUrl: PropTypes.string.isRequired,
  handleServiceRemove: PropTypes.func.isRequired,
  customerPerson: PropTypes.any,
  channelConfigs: PropTypes.shape({
    origin_url: PropTypes.string.isRequired,
    full_showcase_name: PropTypes.string,
    similar_url_pattern: PropTypes.string.isRequired,
  }).isRequired,
  isSoldOut: PropTypes.bool.isRequired,
  isItemNotAllowedForCompanies: PropTypes.bool.isRequired,
  shippingTimes: PropTypes.array.isRequired,
  isLastItemForKey: PropTypes.bool,
  currency: PropTypes.string,
  tax: PropTypes.shape({
    icms: PropTypes.string,
    importValue: PropTypes.string,
    iof: PropTypes.string,
  }),
};

export default BasketItem;
