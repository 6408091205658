import isEmpty from 'lodash/isEmpty';

import * as customerMessages from 'constants/customer-messages';

import { setSoldOut } from 'utils/product';

import * as ShipmentModels from 'api/models/shipment-models';

import * as BasketApi from 'api/basket-api';

import { receivedGiftCard } from 'reducers/gift-card';
import { fail } from 'reducers/fail';
import { unauthorizedLogout } from 'reducers/unauthorized';
import { setPromocode } from 'reducers/promocode';
import {
  receivedShipmentPackages,
  receivedShipment,
  trackShipment,
  receivedUnavailable
} from 'reducers/shipment';
import { metricsError } from 'reducers/metrics';
import { updateViolationOrderByQuantity } from 'reducers/order';

export const REQUEST = 'basket/REQUEST';
export const RECEIVED = 'basket/RECEIVED';
export const UPDATE_ITEM_QTY = 'basket/UPDATE_ITEM_QTY';
export const DID_UPDATE_ITEM_QTY = 'basket/DID_UPDATE_ITEM_QTY';
export const DID_UPDATE_ITEM_QTY_GA4 = 'basket/DID_UPDATE_ITEM_QTY_GA4';
export const TRIGGER_CLICK_EVENT_GA4 = 'basket/TRIGGER_CLICK_EVENT_GA4';
export const REMOVE_ITEM = 'basket/REMOVE-ITEM';
export const DID_REMOVE_ITEM = 'basket/DID-REMOVE-ITEM';
export const DID_REMOVE_ITEM_GA4 = 'basket/DID_REMOVE_ITEM_GA4';
export const REMOVE_WARRANTY = 'basket/REMOVE-WARRANTY';
export const DID_REMOVE_WARRANTY = 'basket/DID-REMOVE-WARRANTY';
export const REMOVE_SERVICES = 'basket/REMOVE-SERVICES';
export const DID_REMOVE_SERVICES = 'basket/DID-REMOVE-SERVICES';
export const SET_BASKET_ADDRESS = 'basket/SET-ADDRESS';
export const REQUEST_PACKAGES = 'basket/REQUEST-PACKAGES';
export const RECEIVED_PACKAGES = 'basket/RECEIVED-PACKAGES';
export const ERROR = 'basket/ERROR';
export const CLEAR_ERROR = 'basket/CLEAR-ERROR';
export const FINISHED = 'basket/FINISHED';
export const RESET = 'basket/RESET';
export const UPDATE_USE_CREDIT = 'basket/UPDATE-USE-CREDIT';
export const DID_UPDATE_USE_CREDIT = 'basket/DID-UPDATE-USE-CREDIT';
export const SET_CAMPAIGN_CODE = 'basket/SET_CAMPAIGN_CODE';
export const SET_ITEMS_SKU = 'basket/SET_ITEMS_SKU';
export const BASKET_READY = 'basket/BASKET_READY';
export const BASKET_CHECKOUT_PROGRESS = 'basket/BASKET_CHECKOUT_PROGRESS';
export const BASKET_VIEW_CART = 'basket/BASKET_VIEW_CART';
export const BASKET_TAX = 'basket/BASKET_TAX';
export const RECEIVED_SHIPMENT_COST = 'basket/RECEIVED_SHIPMENT_COST';
export const RECEIVED_IMPRESSION = 'basket/RECEIVED_IMPRESSION';
export const RECEIVED_SHIPPING_CALC_ERROR = 'basket/RECEIVED_SHIPPING_CALC_ERROR';

export const initialState = {
  isRequesting: false,
  firstRequest: true,
  useCreditRequesting: false,
  basket: {
    id: '',
    products: [],
    totals: {
      to: '0.0',
      cash: '0.0',
      shipment: '0.0',
    },
    productCount: 0,
  },
  credits: {},
  availableCredits: [],
  discounts: {},
  totals: {
    creditAmountInUse: '0.0',
    remainingCreditAmount: '0.0',
    totalAmount: '0.0',
    checkoutAmount: '0.0',
    totalCheckoutAmount: '0.0',
    remainingAmount: '0.0',
    totalProducts: '0.0',
    shippingPrice: '0.0',
  },
  useCredit: false,
  chosenShippingPackages: [], // this is chosen on delivery page
  shippingPackages: {}, // this is fetched on payment page
  addressUuid: '',
  errorMessage: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, { isRequesting: true });
    case RECEIVED:
      const { basket } = action;
      return Object.assign({}, state, {
        basket,
        isRequesting: false,
        firstRequest: false,
      });
    case UPDATE_ITEM_QTY:
      return Object.assign({}, state, { isRequesting: true });
    case DID_UPDATE_ITEM_QTY:
      return Object.assign({}, state, { isRequesting: false });
    case DID_UPDATE_ITEM_QTY_GA4:
      return Object.assign({}, state, { isRequesting: false });
    case REMOVE_ITEM:
      return Object.assign({}, state, { isRequesting: true });
    case DID_REMOVE_ITEM:
      return Object.assign({}, state, { isRequesting: false });
    case DID_REMOVE_ITEM_GA4:
      return Object.assign({}, state, { isRequesting: false });
    case REMOVE_WARRANTY:
      return Object.assign({}, state, { isRequesting: true });
    case DID_REMOVE_WARRANTY:
      return Object.assign({}, state, { isRequesting: false });
    case REMOVE_SERVICES:
      return Object.assign({}, state, { isRequesting: true });
    case DID_REMOVE_SERVICES:
      return Object.assign({}, state, { isRequesting: false });
    case SET_BASKET_ADDRESS:
      const { addressUuid } = action;
      return Object.assign({}, state, { addressUuid });
    case REQUEST_PACKAGES:
      return Object.assign({}, state, { isRequesting: true });
    case RECEIVED_PACKAGES:
      const {
        packages: {
          credits = {},
          availableCredits = [],
          shippingPackages = {},
          useCredit,
          totals = {},
          discounts = {},
        },
      } = action;
      return Object.assign({}, state, {
        isRequesting: false,
        shippingPackages,
        credits,
        availableCredits,
        useCredit,
        totals,
        discounts,
      });
    case ERROR:
      const { errorMessage } = action;
      return Object.assign({}, state, {
        isRequesting: false,
        errorMessage,
      });
    case CLEAR_ERROR:
      return Object.assign({}, state, { errorMessage: '' });
    case FINISHED:
      return Object.assign({}, state, {
        isRequesting: false,
        firstRequest: false,
      });
    case RESET:
      return Object.assign({}, initialState);
    case UPDATE_USE_CREDIT:
      return Object.assign({}, state, {
        useCreditRequesting: true,
      });
    case DID_UPDATE_USE_CREDIT:
      return Object.assign({}, state, {
        isRequesting: false,
        useCreditRequesting: false,
        totals: action.totals,
        useCredit: action.useCredit,
      });
    case SET_CAMPAIGN_CODE:
      return Object.assign({}, state, { campaignCode: action.campaignCode });
    case SET_ITEMS_SKU:
      return Object.assign({}, state, { itemsSku: action.itemsSku });
    case RECEIVED_SHIPMENT_COST:
      const { cost = '0.0' } = action;

      return {
        ...state,
        basket: {
          ...state.basket,
          totals: {
            ...state.basket.totals,
            shipment: cost,
          },
        },
      };
    case BASKET_READY:
    case BASKET_CHECKOUT_PROGRESS:
    case BASKET_VIEW_CART:
    case BASKET_TAX:
    case RECEIVED_IMPRESSION:
    case TRIGGER_CLICK_EVENT_GA4:
    case RECEIVED_SHIPPING_CALC_ERROR:
    default:
      return state;
  }
}

export function requestBasket() {
  return {
    type: REQUEST,
  };
}

export function basketReady() {
  return {
    type: BASKET_READY,
  };
}

export function basketCheckoutProgress() {
  return {
    type: BASKET_CHECKOUT_PROGRESS,
  };
}

export function basketViewCart() {
  return {
    type: BASKET_VIEW_CART,
  };
}

export function basketTax(
  screenName
) {
  return {
    type: BASKET_TAX,
    screenName
  };
}

export function basketShippingCalcImpression() {
  return {
    type: RECEIVED_IMPRESSION,
  };
}

export function basketShippingCalcError(basket, err) {
  return {
    type: RECEIVED_SHIPPING_CALC_ERROR,
    basket,
    error: err
  };
}

export function receivedBasket(basket = {}) {
  return {
    type: RECEIVED,
    basket,
  };
}

export function updateItemQuantity() {
  return {
    type: UPDATE_ITEM_QTY,
  };
}

export function didUpdateItemQuantity(itemId, quantity, product) {
  return {
    type: DID_UPDATE_ITEM_QTY,
    itemId,
    quantity,
    product,
  };
}

export function didUpdateItemQuantityGA4(itemId, quantity, product) {
  return {
    type: DID_UPDATE_ITEM_QTY_GA4,
    itemId,
    quantity,
    product,
  };
}

export function removeItem(items) {
  return {
    type: REMOVE_ITEM,
    items,
  };
}

export function didRemoveItem(items) {
  return {
    type: DID_REMOVE_ITEM,
    items,
  };
}

export function didRemoveItemGA4(items) {
  return {
    type: DID_REMOVE_ITEM_GA4,
    items,
  };
}
export function triggerClickEventGA4(eventName) {
  return {
    type: TRIGGER_CLICK_EVENT_GA4,
    eventName,
  };
}

export function removeWarranty(itemId) {
  return {
    type: REMOVE_WARRANTY,
    itemId,
  };
}

export function didRemoveWarranty(itemId) {
  return {
    type: DID_REMOVE_WARRANTY,
    itemId,
  };
}

export function removeServices(services) {
  return {
    type: REMOVE_SERVICES,
    services,
  };
}

export function didRemoveServices(services, product) {
  return {
    type: DID_REMOVE_SERVICES,
    services,
    product,
  };
}

export function setBasketAddress(addressUuid) {
  return {
    type: SET_BASKET_ADDRESS,
    addressUuid,
  };
}

export function requestPackages() {
  return {
    type: REQUEST_PACKAGES,
  };
}

export function receivedPackages(packages) {
  return {
    type: RECEIVED_PACKAGES,
    packages,
  };
}

export function error(errorMessage) {
  return { type: ERROR, errorMessage };
}

export function clearError() {
  return { type: CLEAR_ERROR };
}

export function finish() {
  return {
    type: FINISHED,
  };
}

export function resetBasket() {
  return { type: RESET };
}

export function updateUseCredit() {
  return {
    type: UPDATE_USE_CREDIT,
  };
}

export function didUpdateUseCredit(totals, useCredit) {
  return {
    type: DID_UPDATE_USE_CREDIT,
    totals,
    useCredit,
  };
}

export function receivedShipmentCost(cost) {
  return {
    type: RECEIVED_SHIPMENT_COST,
    cost,
  };
}

export function fetchBasket() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const {
        basket = {},
        shipment: { shipment } = { shipment: {} },
        order,
      } = getState();

      if (basket.isRequesting) {
        return reject('Already doing some basket request.');
      }

      dispatch(requestBasket());

      return BasketApi.getBasket(shipment.zipcode)
        .then(({ basketModel, json }) => {
          let response = basketModel;
          json.address_detail = json.address_detail || json.address;
          dispatch(setBasketAddress(json?.shipping_address_id)); //Used to show current address on address list
          if(basketModel?.unavailableDeliveries.length > 0) {
            dispatch(receivedUnavailable(json.unavailable_deliveries));
          }
          if (basketModel.packages?.length > 0) {
            dispatch(
              receivedShipmentPackages(
                ShipmentModels.shipmentPackages(basketModel.packages)
              )
            );

            const shipmentModel = ShipmentModels.shipment(json);

            dispatch(receivedShipmentCost(json.total_delivery_cost)); // Used to build cost
            dispatch(receivedShipment(shipmentModel)); // Used to build shipping address message
            dispatch(trackShipment(shipmentModel)); // Used for GTM
          }

          if (order?.itemsOutOfStock.length && basketModel.products) {
            const updatedProducts = setSoldOut(
              basketModel.products,
              basketModel.packages,
              order.itemsOutOfStock
            );
            response = {
              ...basketModel,
              products: updatedProducts,
            };
          }

          if (order?.orderViolations?.length && basketModel.products) {
            dispatch(
              updateViolationOrderByQuantity(
                basketModel.products,
                order.orderViolations
              )
            );
          }

          dispatch(receivedBasket(response));

          if (
            Array.isArray(response.unavailableDeliveries)
            && !!response.unavailableDeliveries.length
          ) {
            dispatch(basketShippingCalcError(response));
          }

          return resolve(response);
        })
        .catch((err) => {
          if(shipment.zipcode) {
            dispatch(basketShippingCalcError({}, err));
          }
          if (err.status !== 404) {
            dispatch(fail(customerMessages.fetchBasketError, err.status));
          } else {
            dispatch(receivedBasket(initialState.basket));
          }
          if (err && err.response && err.response.body) {
            dispatch(
              metricsError('frete sacola', err.response.body.error_message)
            );
          }
          dispatch(finish());
          return reject(err);
        });
    });
}

export function fetchBasketForGTM() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { basket = {} } = getState();

      if (basket.isRequesting) {
        return reject('Already doing some basket request.');
      }

      if (!!basket.basket.id) {
        return resolve('Already have basket.');
      }

      return BasketApi.getBasket()
        .then(({ basketModel, json }) => {
          dispatch(receivedBasket(basketModel));
          dispatch(setBasketAddress(json?.shipping_address_id)); //Used to show current address on address list
          return resolve(basketModel);
        })
        .catch((err) => {
          if (err.status !== 404) {
            dispatch(fail(customerMessages.fetchBasketError, err.status));
          } else {
            dispatch(receivedBasket(initialState.basket));
          }
          return reject(err);
        });
    });
}

export function putItemQuantity(storeId, itemId, quantity, product) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { basket = {} } = getState();

      if (basket.isRequesting) {
        return reject('Already doing some basket request.');
      }

      dispatch(updateItemQuantity());

      return BasketApi.updateItemQuantity(storeId, itemId, quantity).then(
        () => {
          dispatch(didUpdateItemQuantityGA4(itemId, quantity, product));
          dispatch(didUpdateItemQuantity(itemId, quantity, product));
          dispatch(fetchBasket());
          return resolve();
        },
        (err) => {
          const { status } = err;

          if (status === 409) {
            dispatch(fail(customerMessages.updateItemOutOfStock, status));
          } else {
            dispatch(fail(customerMessages.updateItemQuantityError, status));
          }

          dispatch(
            metricsError(
              'item alterar quantidade',
              err.response.body.error_message
            )
          );

          dispatch(finish());
          return reject(err);
        }
      );
    });
}

export function addProduct(storeId, itemId) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { basket = {} } = getState();

      if (basket.isRequesting) {
        return reject('Already doing some basket request.');
      }

      return BasketApi.addProduct(storeId, itemId).then(
        () => {
          return resolve();
        },
        (err) => {
          const { status } = err;

          if (status === 409) {
            dispatch(fail(customerMessages.updateItemOutOfStock, status));
          } else {
            dispatch(fail(customerMessages.updateItemQuantityError, status));
          }

          dispatch(
            metricsError('adicionar produto', err.response.body.error_message)
          );

          dispatch(finish());
          return reject(err);
        }
      );
    });
}

export function deleteItem(items) {
  /* Remove one or more items from basket
   * @param {Object[]} items Array with objects
   * @param {string} items[].id SKU of the product
   * @param {string} items[].storeId ID of the seller
   */
  return (dispatch, getState) => {
    const { basket = {}, order = {} } = getState();

    if (basket.isRequesting) {
      return;
    }

    dispatch(removeItem(items));

    return BasketApi.deleteItem(items)
      .then(() => {
        dispatch(didRemoveItemGA4(items));
        return dispatch(didRemoveItem(items));
      })
      .then(() => {
        return dispatch(fetchBasket());
      })
      .catch((err = {}) => {
        dispatch(error(customerMessages.deleteItemError));

        if (err.response && err.response.body) {
          dispatch(
            metricsError('deletar item', err.response.body.error_message)
          );
        }

        return dispatch(finish());
      });
  };
}

export function deleteWarranty(storeId, itemId) {
  return (dispatch, getState) => {
    const { basket = {} } = getState();

    if (basket.isRequesting) {
      return;
    }

    dispatch(removeWarranty(itemId));

    return BasketApi.deleteWarranty(storeId, itemId)
      .then(() => {
        return dispatch(didRemoveWarranty(itemId));
      })
      .then(() => {
        return dispatch(fetchBasket());
      })
      .catch((err) => {
        dispatch(error(customerMessages.deleteWarrantyError));
        return dispatch(finish());
      });
  };
}

export function deleteServices(services, product) {
  return (dispatch, getState) => {
    const { basket = {} } = getState();

    if (basket.isRequesting) {
      return;
    }

    dispatch(removeServices(services));

    return BasketApi.deleteServices(services)
      .then(() => {
        return dispatch(didRemoveServices(services, product));
      })
      .then(() => {
        return dispatch(fetchBasket());
      })
      .catch((err) => {
        dispatch(fail(customerMessages.deleteServiceError, err.status));
        return dispatch(finish());
      });
  };
}

export function setCampaignCode(campaignCode) {
  return {
    type: SET_CAMPAIGN_CODE,
    campaignCode,
  };
}

export function setItemsSku(itemsSku) {
  return {
    type: SET_ITEMS_SKU,
    itemsSku,
  };
}

export function fetchPackages() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { basket = {} } = getState();

      if (basket.isRequesting) {
        return reject('Already doing some basket request.');
      }

      dispatch(requestPackages());

      return BasketApi.getShippingPackages()
        .then((json) => {
          if (!isEmpty(json.giftCardInfo)) {
            dispatch(receivedGiftCard(json.giftCardInfo));
          }
          dispatch(receivedPackages(json));
          dispatch(setPromocode(json.promocode, json.discounts.promocode > 0));
          dispatch(setCampaignCode(json.campaignCode));
          dispatch(setItemsSku(json.itemsSku));
          return resolve(json);
        })
        .catch((err) => {
          const { status } = err;
          if (status === 401) {
            dispatch(unauthorizedLogout(err));
            dispatch(fail(customerMessages.expired, status));
          }
          dispatch(finish());
          return reject(err);
        });
    });
}

export function updateCredit(useCredit) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { basket = {} } = getState();

      if (basket.isRequesting) {
        return reject('Already doing some basket request.');
      }

      dispatch(updateUseCredit());

      return BasketApi.putCredit(useCredit).then(
        (json) => {
          dispatch(didUpdateUseCredit(json.totals, json.useCredit));
          return resolve(json);
        },
        (err) => {
          const { status } = err;

          if (status === 401) {
            dispatch(unauthorizedLogout(err));
            dispatch(fail(customerMessages.expired, status));
          } else if (status !== 400) {
            dispatch(fail(customerMessages.failSetAddress, status));
          }

          dispatch(finish());
          return reject(err);
        }
      );
    });
}
