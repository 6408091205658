

export const acceptPrivacyPolicyGA4 = (checked) => {
  const click = checked ? 'limpar':'aplicar';

  const layer = {};
  layer.event = 'select_content';
  layer.content_type = 'identificacao:criar-conta';
  layer.content_id = `${click}:aceito-termos`;

  return layer;
};








