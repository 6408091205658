
export const unknowZipCodeGA4 = () => {

  const layer = {};
  
  layer.event = 'select_content';
  layer.content_type = 'identificacao:criar-conta';
  layer.content_id = 'nao-sei-meu-cep';

  return layer;
};